import { _Client } from '@/api-client';
import { UploadOSMWorkRecordInput, PageResultOfListOfOSMWorkRecordListDto, SearchOSMWorkRecordInput, ExaminerProfileStatusEnum, OSMWorkRecordListDto } from '@/api-client/client';
import { createVNode, defineComponent, h, reactive, ref } from "vue";
import dayjs, { Dayjs } from 'dayjs';
import { GetBase64 } from '@/utils/common';
import Pagination from '@/components/Pagination/index.vue'
import { UploadOutlined, DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { message, Modal } from 'ant-design-vue';

export default defineComponent({
  components: {
    UploadOutlined,
    DownloadOutlined,
    ExclamationCircleOutlined,
    Pagination
  },
  setup() {

    const columns = [
      {
        title: 'Actual Marking Date',
        dataIndex: 'testTime',
        key: 'actualMarkingDate',
        width: 120
      },
      {
        title: 'Examiner No.',
        dataIndex: 'examinerNo',
        key: 'examinerNo',
        width: 120
      },
      {
        title: 'Examiner Name',
        dataIndex: 'examinerName',
        key: 'examinerName',
        width: 120
      },
      {
        title: 'Total items Marked',
        dataIndex: 'totalItemsMarked',
        key: 'totalItemsMarked',
        width: 120
      },
      {
        title: 'Action',
        key: 'action',
        width: 80
      }
    ]

    // 文件list
    const tableLoading = ref(false);
    const pageLoding = ref(false);
    const downloadLoding = ref(false);
    const uploadLoding = ref(false);
    const searchDate = ref<[string, string]>();
    const dateRange = ref<[Dayjs, Dayjs]>();
    const uploadInput = reactive(new UploadOSMWorkRecordInput());
    const dataSource = reactive<PageResultOfListOfOSMWorkRecordListDto>(new PageResultOfListOfOSMWorkRecordListDto());
    const searchParames = reactive<SearchOSMWorkRecordInput>(new SearchOSMWorkRecordInput({ pageIndex: 1, pageSize: 10 }));

    // 文件上传后
    const uploadFile = async (file: File) => {
      pageLoding.value = true;
      uploadLoding.value = true;
      const zipFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'.split(',');
      const isExcel = zipFileType.includes(file.type);
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1);
      if (!isExcel || fileSuffix != 'xlsx') {
        message.warning('The uploaded file format is incorrect. Please upload the OSM examiner\'s work record using the template.');
        pageLoding.value = false;
        uploadLoding.value = false;
        return false;
      }

      if (file.size > 2 * 1024 * 1024) {
        message.warning("File Size：Within 2M.");
        pageLoding.value = false;
        uploadLoding.value = false;
        return false;
      }

      uploadInput.fileBase64String = await GetBase64(file) as string;
      uploadInput.fileName = file.name;
      _Client.uploadOSMWorkRecordClient.uploadOSMWorkRecord(uploadInput).then((rep) => {
        if(rep.isSuccessfull){
          message.success("Upload Success!");
        }else{
          rep.errorMessage?.forEach(item => {
            Modal.warning({
              title: 'Warning',
              width: 650,
              okText: "Confirm",
              content: h('div', {}, item)
            })
          });
        }
        getList();
      }).finally(() => { pageLoding.value = false; uploadLoding.value = false; });
    };

    // 下载模板
    const downloadTemplate = () => {
      pageLoding.value = true;
      downloadLoding.value = true;
      _Client.uploadOSMWorkRecordClient.downloadImportOSMWorkRecordCandidateTemplate().then((excelStream) => {
        let link = document.createElement("a");
        if (excelStream) {
          link.href = window.URL.createObjectURL(excelStream.data);
        }
        link.download = "OSM template.xlsx";
        link.click();
        link.remove();
        URL.revokeObjectURL(link.href);
        link.download = "";
      }).then(() => { pageLoding.value = false; downloadLoding.value = false; });
    }

    const search = () => {
      searchParames.pageIndex = 1;
      getList();
    }

    const getList = () => {
      tableLoading.value = true;
      if(searchDate.value?.[0] && searchDate.value?.[1]){
        searchParames.startDate = new Date(searchDate.value![0]);
        searchParames.endDate = new Date(searchDate.value![1]);
      }else{
        searchParames.startDate = undefined;
        searchParames.endDate = undefined;
      }

      _Client.uploadOSMWorkRecordClient.searchOSMWorkRecord(searchParames).then(rep => {
        dataSource.init(rep)
      }).finally(() => tableLoading.value = false)
    }

    const showConfirm = (record: OSMWorkRecordListDto) => {
      Modal.confirm({
        title: 'Do you want to delete this record？',
        icon: createVNode(ExclamationCircleOutlined),
        okText: "Yes",
        cancelText: "No",
        onOk() {
          deleteOSMWorkRecord(record.id);
        },
      });
    };

    const deleteOSMWorkRecord = (id: string) => {
      tableLoading.value = true;
      _Client.uploadOSMWorkRecordClient.deleteOSMWorkRecord(id).then(() => {
        message.success("success!");
        getList();
      }).finally(() => tableLoading.value = false)
    }

    const changeDate = (dates: [Dayjs, Dayjs], dateStrings: [string, string]) => {
      searchDate.value = dateStrings;
  }

    const resetSearchParams = () => {
      searchParames.examinerNo = undefined;
      searchDate.value = undefined;
      dateRange.value = undefined;
      getList()
    }

    return {
      columns,
      tableLoading,
      downloadLoding,
      uploadLoding,
      pageLoding,
      searchDate,
      dateRange,
      dataSource,
      searchParames,
      examinerProfileStatusEnum: ExaminerProfileStatusEnum,
      uploadFile,
      downloadTemplate,
      getList,
      showConfirm,
      resetSearchParams,
      changeDate,
      search,
    }
  }
})